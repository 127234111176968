@import './reboot';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.app {
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
}

.main {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex-grow: 1;
}

.container {
	margin: 0 auto;
	max-width: 1200px;
}

.hide {
	display: none !important;
}

button,
.btn {
	padding: 10px 30px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #383838;
	text-transform: uppercase;
	background: #dfdfdf;
	border: 1px solid rgba(255, 255, 255, .3);
	border-radius: 30px;
	font-size: 15px;
	font-weight: 700;
	line-height: 1.4;
	text-decoration: none;
	cursor: pointer;
	transition: .3s;

	&:hover {
		color: #fff;
		background: #111;
	}

	&:disabled {
		color: #fff;
		background: #111111ae;
		pointer-events: none;
	}
}

.section {
	padding: 100px 0;

	&-title {
		margin: 0 auto;
		padding: 9px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		border: 2px solid #e1e1e1;
		border-radius: 40px;
		color: rgba(0, 0, 0, .7);
		font-size: 13px;
		font-weight: 800;
		text-align: center;
		text-transform: uppercase;
	}

	&-heading {
		margin-top: 15px;
		font-size: 50px;
		font-weight: 800;
		line-height: 1.1;
		text-align: center;
	}
}

.img-overlay {
	position: absolute;
	top: 0;
	left: 0;
	background: #fafafa;
	width: 100%;
	height: 100%;
}

.viewall {
	position: relative;
	margin: 30px auto 0;
	display: flex;
	flex-direction: column;
	width: fit-content;
	overflow: hidden;
	height: 24px;
	text-decoration: none;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		height: 2px;
		background: #333;
		transition: .3s .1s;
	}

	&:hover::before {
		width: 100%;
	}

	span {
		color: #333;
		font-size: 14px;
		font-weight: 800;
		line-height: 24px;
		text-align: center;
		text-transform: uppercase;
		transition: .3s;
	}

	&:hover span {
		transform: translateY(-100%);
	}
}

.cursor {
	position: absolute;
	width: 0;
	height: 0;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000;
	opacity: 0;
	transition: opacity .3s, width .3s, height .3s;
	pointer-events: none;
	z-index: 10;

	img {
		width: 34px;
		filter: invert(1);
	}
}

.logo {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 32px;
	overflow: hidden;
	text-decoration: none;

	span {
		font-size: 32px;
		line-height: 32px;
		font-weight: 800;
		color: #000;
		transition: .3s;
	}

	&:hover {
		span {
			transform: translateY(-100%);
		}
	}
}

.td-none {
	text-decoration: none;
}

.preventLink {
	pointer-events: none;
}

@media only screen and (width < 1300px) {
	.container {
		max-width: calc(100% - 100px);
	}
}

@media only screen and (width < 768px) {
	.container {
		max-width: calc(100% - 60px);
	}
	.section {
		padding: 60px 0;
	}
}

@media only screen and (width < 480px) {
	.container {
		max-width: 90%;
	}
	.logo {
		height: 25px;
	
		span {
			font-size: 25px;
			line-height: 25px;
		}
	}
	.section {	
		&-heading {
			font-size: 30px;
		}
	}
}