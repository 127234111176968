.menu {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: .5s .5s;

    &.active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;

        a {
            transform: translateX(0);
            transition-delay: .5s;
        }
    }

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        transform: translateX(-130%);
        transition: transform .4s 0s;

        span {
            opacity: .6;
            color: grey;
            letter-spacing: 2px;
            margin-right: 15px;
            font-size: 15px;
            font-weight: 600;
            line-height: 12px;
            transform: rotate(-90deg);
        }

        &:hover {
            .menu-item-text {
                transform: translateY(-100%);
            }
        }
    }

    &-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 4px;
        overflow: hidden;
    }

    &-item {
        height: 86px;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &-text {
            color: #000;
            text-transform: capitalize;
            font-size: 80px;
            font-weight: 800;
            line-height: 86px;
            transition: transform .4s;

            &:last-child {
                color: #fff;
                text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
            }
        }
    }
}

@media only screen and (width < 768px) {
    .menu {
        &-item {
            height: 76px;
            &-text {
                font-size: 70px;
                line-height: 76px;
            }
        }
    }
}

@media only screen and (width < 480px) {
    .menu {
        &-item {
            height: 66px;
            &-text {
                font-size: 60px;
                line-height: 66px;
            }
        }
    }
}