.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    display: inline-block;
    overflow: hidden;
    background: none;
    &-box {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
        div {
            position: absolute;
            border-width: 10.799999999999999px;
            border-style: solid;
            opacity: 1;
            border-radius: 50%;
            animation: spinner-box 1.282051282051282s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            box-sizing: content-box;
            &:nth-child(1) {
                border-color: #5b0092;
                animation-delay: 0s;
            }
            &:nth-child(2) {
                border-color: #5b0092;
                animation-delay: -0.641025641025641s;
            }
        }
    }
}

@keyframes spinner-box {
    0% {
        top: 49.199999999999996px;
        left: 49.199999999999996px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 6.6px;
        left: 6.6px;
        width: 85.2px;
        height: 85.2px;
        opacity: 0;
    }
}