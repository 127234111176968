.pageup {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 11;
    transition: bottom 1s, background-color .3s;
    cursor: pointer;

    &.hidePageUpBtn {
        bottom: -100px;
    }

    &:hover {
        background-color: #8f8f8f;
    }
}