.social-content {
    display: flex;
    align-items: center;
    column-gap: 20px;

    a {
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            transition: .3s;
        }

        img:last-child {
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            img {
                transform: translateY(-100%);
            }
            img:last-child {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

@media only screen and (width < 480px) {
    .header {
        .social-content {
            display: none;
        }
    }
}