.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 35px 0;
    background: rgba(255, 255, 255, 0);
    z-index: 11;
    transition: .3s;

    &.colored {
        background: rgba(255, 255, 255, 1);
    }

    .container {
        max-width: min(92%, 1440px);
    }

    &-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .hamburger {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 24px;
        width: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;

        span {
            width: 40px;
            height: 2px;
            background: #000;
            transition: .3s;
        }

        &.active {
            span:first-child {
                transform: translateY(6px) rotate(-45deg);
            }

            span:last-child {
                transform: translateY(-6px) rotate(45deg);
            }
        }
    }
}

@media only screen and (width < 768px) {
    .header {
        padding: 30px 0;
    }
}

@media only screen and (width < 480px) {
    .header {
        padding: 20px 0;
        .container {
            max-width: 90%;
        }
        .hamburger {
            left: auto;
            right: 0;
            transform: none;
        }
    }
}