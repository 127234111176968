.footer {
    margin-top: 60px;
    padding-bottom: 30px;
    overflow: hidden;

    &-wrapper {
        display: flex;
        justify-content: space-between;
        column-gap: 40px;
    }

    &-column {
        width: 270px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        &-title {
            font-size: 18px;
            font-weight: 800;
            line-height: 30px;
            letter-spacing: -1px;
        }

        &-links {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            a {
                margin: 0;

                span {
                    letter-spacing: -1px;
                }
            }
        }
    }

    .contacts {
        display: flex;
        flex-direction: column;
        font-style: normal;

        >span {
            margin-bottom: 30px;
            font-size: 17px;
            font-weight: 600;
            line-height: 1.4;
            color: rgba(77, 77, 77, .9);
        }

        &-item {
            display: flex;
            align-items: center;
            column-gap: 5px;

            span,
            a {
                font-size: 17px;
                font-weight: 600;
                line-height: 1.4;
                color: rgba(77, 77, 77, .9);
                text-decoration-color: transparent;
                text-underline-offset: 3px;
                transition: .3s;
            }

            a:hover {
                color: #000;
                text-decoration-color: #000;
            }
        }
    }

    .copyright {
        margin-top: 30px;
        color: rgba(77, 77, 77, .9);
        font-size: 15px;
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: -1px;

        a {
            color: #8f8f8f;
            transition: .3s;

            &:hover {
                color: #000;
            }
        }
    }
}

@media only screen and (width < 1024px) {
    .footer {
        .container {
            max-width: 92%;
        }   
        &-wrapper {
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 20px 40px;
        }
        &-column {
            width: unset;
            flex-basis: 20%;
            flex-grow: 1;
            &:first-child {
                flex-grow: 2;
            }
        }
    }
}

@media only screen and (width < 768px) {
    .footer { 
        &-wrapper {
            gap: 40px;
        }
        &-column {
            flex-basis: calc((100% - 40px) / 2);
            flex-grow: 1;
            &:first-child {
                flex-grow: 1;
            }
        }
    }
}

@media only screen and (width < 480px) {
    .footer { 
        &-wrapper {
            gap: 50px;
        }
        &-column {
            flex-basis: 100%;
        }
    }
}